html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  background: #fafafa;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

#app-root {
  position: relative;
  z-index: 0;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 225ms ease-in;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  position: absolute;
  top: 0;
  z-index: 1;
  opacity: 1;
  transition: opacity 275ms ease-in 275ms;
}

.modal-in-enter, .modal-in-exit {
  position: absolute;
}

.modal-in-exit {
  filter: brightness(1);
}

.modal-in-exit.modal-in-exit-active {
  transition: filter 300ms cubic-bezier(0.0, 0.0, 0.2, 1);
  filter: brightness(0.5);
}

.modal-in-enter {
  top: 0;
  opacity: 0;
  transform: translate3d(0, 100vh, 0);
}

.modal-in-enter.modal-in-enter-active {
  z-index: 1;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: transform 300ms cubic-bezier(0.0, 0.0, 0.2, 1),
    opacity 150ms cubic-bezier(0.0, 0.0, 0.2, 1);
}

.modal-out-enter, .modal-out-exit {
  position: absolute;
}

.modal-out-exit {
  top: 0;
  z-index: 1;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.modal-out-exit.modal-out-exit-active {
  opacity: 0;
  transform: translate3d(0, 100vh, 0);
  transition: transform 250ms cubic-bezier(0.4, 0.0, 1, 1),
    opacity 125ms cubic-bezier(0.4, 0.0, 1, 1) 125ms;
}

.modal-out-enter {
  filter: brightness(0.5);
}

.modal-out-enter.modal-out-enter-active {
  filter: brightness(1);
  transition: filter 250ms cubic-bezier(0.4, 0.0, 1, 1);
}

/*
  the following is a fix for SIG-353
  MUI's tabset can render an oval while transitioning from full height down to 1px.
  this fix probably belongs in MUI itself; as a supplement for this 'all' transition:
    all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
*/
div[role="tablist"] * {
  transition-property: left, width;
}
